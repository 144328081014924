'use strict';

angular.module('managerApp.auth').run(function ($rootScope, $location, Auth, toast) {
    // Redirect to login if route requires auth and the user is not logged in, 
    // or doesn't have required role
    $rootScope.$on('$routeChangeStart', function (event, next) {
        const path = $location.path(), currentUser = Auth.getCurrentUser() || {};
        // user not conneted, go to login page
        if (!currentUser._id && ['/login', '/logout'].indexOf(path) < 0) {
            $location.path('/login');
            return;
        }
        
        // user is connected
        $rootScope.currentUser = currentUser;

        if (!next.authenticated && !next.hasPermissions) {
            return;
        }

        if (typeof next.authenticated === 'boolean') {
            if (Auth.isLoggedIn()) {
                return;
            } else {
                event.preventDefault();
                $location.path('/login');
            }
        }

        if (Array.isArray(next.hasPermissions)) {
            if (Auth.hasPermissions(next.hasPermissions)) {
                return;
            } else {
                toast.show("Vous n'avez pas le droit d'accèder à ce contenu" +
                        " ou d'effectuer cette opération", 'error');
                $location.path(Auth.isLoggedIn() ? '/' : '/login'); 
            }
        }

    });
});